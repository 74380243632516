<template>
  <div class="page">
    <swiper :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl">
      <div class="parallax-bg" slot="parallax-bg" data-swiper-parallax="-23%"></div>
      <swiper-slide class="swiper-slide historique">
        <div class="content-slider">
          <div class="title" data-swiper-parallax="-100"><b>Mes rendez-vous</b></div>

        </div>
      </swiper-slide>
    </swiper>
    <div class=" body">
      <!-- ROW 1-->

      <div class="vx-row colors cursor-pointer">
        <div class="vx-col w-1/2 md:w-1/3 xl:w-1/5">
          <vx-card
            :title="aVenir"
            v-on:click.native="getRendezVous('A venir')"
            subtitle="A venir"
            :title-color="colors.white"
            :card-background="colors.orange2"
            :content-color="colors.white">
          </vx-card>
        </div>

        <div class="vx-col w-1/2 md:w-1/3 xl:w-1/5 cursor-pointer ">
          <vx-card
            :title="aStatuer"
            subtitle="A statuer"
            v-on:click.native="getRendezVous('A statuer')"
            :title-color="colors.white"
            :card-background="colors.green2"
            :content-color="colors.white">
          </vx-card>
        </div>

        <div class="vx-col w-1/2 md:w-1/3 xl:w-1/5 cursor-pointer">
          <vx-card
            :title="termine"
            subtitle="Réalisé"
            v-on:click.native="getRendezVous('Réalisé')"
            :title-color="colors.white"
            :card-background="colors.yellow2"
            :content-color="colors.white">
          </vx-card>
        </div>
        <div class="vx-col w-1/2 md:w-1/3 xl:w-1/5 cursor-pointer">
          <vx-card
            :title="enAttenteAnnul"
            subtitle="En attente d'annulation"
            v-on:click.native="getRendezVous('En attente d\'annulation')"
            :title-color="colors.white"
            :card-background="colors.primary"
            :content-color="colors.white"
          class="no-padding">
          </vx-card>
        </div>
        <div class="vx-col w-1/2 md:w-1/3 xl:w-1/5 cursor-pointer">
          <vx-card
            :title="annule"
            subtitle="Annulés"
            v-on:click.native="getRendezVous('Annulé')"
            :title-color="colors.white"
            :card-background="colors.blue2"
            :content-color="colors.white">
          </vx-card>
        </div>
      </div>
      <!-- Tableau -->
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center">

          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ rendezVous.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : rendezVous.length }} of {{ rendezVous.length }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>

                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
            <vs-input class="mb-4 md:mb-0 mr-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
            <download-csv
              :data  = "csvRdv"
              name = "Export_Rendez_Vous.csv"
              class = "vs-component vs-button mb-4 md:mb-0 vs-button-primary vs-button-filled">
              Exporter
            </download-csv>
            </div>
        </div>
        <div class="mt-5">
          <vs-tabs>
            <vs-tab  label="Afficher tout" @click="getRendezVous()" >
            </vs-tab>
          </vs-tabs>
          <div class="tab-text">
            <ag-grid-vue
              ref="agGridTable"
              :gridOptions="gridOptions"
              class="ag-theme-material w-100 my-4 ag-grid-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rendezVous"
              rowSelection="multiple"
              colResizeDefault="shift"
              :animateRows="true"
              :floatingFilter="true"
              :pagination="true"
              :paginationPageSize="paginationPageSize"
              :suppressPaginationPanel="true"
              :enableRtl="$vs.rtl">
            </ag-grid-vue>
            <vs-pagination
              :total="totalPages"
              :max="maxPageNumbers"
              v-model="currentPage" />
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import { AgGridVue } from 'ag-grid-vue';
import axios from '../../../axios.js';
import { colors } from '../../../../themeConfig';
import store from '../../../store/store';
import router from '../../../router';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import JsonCSV from 'vue-json-csv';

export default {
  components: {
    swiper,
    swiperSlide,
    AgGridVue,
    downloadCsv: JsonCSV,
  },
  filters: {
    formDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm:ss');
      }
    },
  },
  data() {
    return {
      // Area charts
      first: '',
      last: '',
      next: '',
      csvRdv: [],
      searchQuery: '',
      idLigne: this.$route.query.id,
      gridOptions: {
        onRowClicked(event) {
          router.push({ name: 'user-appointments-id', params: { id: event.data.id } });
        },
        getRowNodeId(data) { return data.id; },
      },
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      termine: '',
      colors,
      annule: '',
      aStatuer: '',
      enAttenteAnnul: '',
      aVenir: '',
      rendezVous: '',
      columnDefs: [
        {
          headerName: 'Id',
          field: 'id',
          filter: true,
          width: 80,
        },
        {
          headerName: 'Téleconseiller',
          field: 'teleconseiller',
          filter: true,
          width: 200,
          cellRenderer(params) {
            if (params.value !== undefined) {
              return `${params.value.lastName} ${params.value.firstName}`;
            }
            return 'Null';
          },
        },
        {
          headerName: 'Annonce',
          field: 'ad',
          filter: true,
          width: 150,
          cellRenderer(params) {
            if (params.value !== undefined) {
              return `<a href="${params.value.adUrl}" target="_blank" rel="noopener" >${params.value.reference}</a>`;
            }
            return 'Null';
          },
        },
        {
          headerName: 'Mandat',
          field: 'mandat',
          filter: true,
          width: 150,
        },
        {
          headerName: 'Commande',
          field: 'commande',
          filter: true,
          cellRenderer(params) {
            if (params.value !== undefined) {
              return params.value.reference;
            }
            return 'Null';
          },
          width: 150,
        },
        {
          headerName: 'Date et Heure',
          field: 'dateDebut',
          filter: true,
          cellRenderer(params) {
            if (params.value !== undefined) {
              return params.value;
            }
            return 'Null';
          },
          width: 180,
        },
        {
          headerName: 'Statut',
          field: 'statut',
          filter: true,
          cellRenderer(params) {
            let classe = '';
            switch (params.value) {
              case 'Réalisé':
                classe = 'Termine';
                break;
              case 'Annulé':
                classe = 'Annule';
                break;
              case 'A statuer':
                classe = 'Astatuer';
                break;
              case "En attente d'annulation":
                classe = 'enAttenteAnnul';
                break;
              default:
                classe = 'Avenir';
            }
            return `<div class="status"><span class="${classe}">${params.value}</span></div>`;
          },
          width: 200,
        },
      ],

    };
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    getCsvData(rendezVous) {
      let oneLine = {};
      const dataResult = [];
      rendezVous.forEach((value) => {
        oneLine = {
          id: value.id,
          commande: `${value.commande.id} | ${value.commande.reference}`,
          negociateur: `${value.negociateur.firstName} ${value.negociateur.lastName}`,
          teleconseiller: (value.teleconseiller !== null) ? `${value.teleconseiller.firstName} ${value.teleconseiller.lastName} , email :${value.teleconseiller.email}` : '',
          annonce: (value.ad !== null) ? value.ad.reference : '',
          commune: (value.ad !== null) ? `${value.ad.postalCode} ${value.ad.adCity}` : '',
          type_bien: (value.ad !== null) ? value.ad.tradeType.name : '',
          prix: (value.ad !== null) ? value.ad.price : '',
          Numero_telephone: (value.ad !== null) ? value.ad.mobileNumber : '',
          date: `${value.dateDebut} - ${value.dateFin}`,
          statut: value.statut,
          mandat: value.mandat,
          commentaire: value.commentaire,
          dateAnnulation: value.dateAnnulation,
          motifAnnulation: value.motifAnnulation,
        };
        dataResult.push(oneLine);
      });
      this.csvRdv = dataResult;
    },
    getRendezVous(statut) {
      // Les commandes
      let urlTotal = '';
      const { user } = store.state.AppActiveUser;
      if (statut == null) {
        urlTotal = `/api/rendez_vouses?negociateur=${user.id}`;
      } else {
        urlTotal = `/api/rendez_vouses?statut=${statut}&negociateur=${user.id}`;
      }

      axios.get(urlTotal, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/ld+json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((response) => {
          this.rendezVous = response.data['hydra:member'];
          this.getCsvData(response.data['hydra:member']);
        })
        .catch(() => {
          this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page.').goAway(1500);
        });
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    details(id) {
      router.push({ name: 'details', params: { id } });
    },
  },
  watch: {
    '$route.query.status': function (newVal) {
      let statut = '';
      switch (newVal) {
        case 'realise':
          statut = 'Réalisé';
          break;
        case 'astatuer':
          statut = 'A statuer';
          break;
        case 'annules':
          statut = 'Annulé';
          break;
        case 'enAttenteAnnul':
          statut = "En attente d'annulation";
          break;
        default:
          statut = 'A venir';
      }
      this.getRendezVous(statut);
      // or this.openPopup(newVal) is this suits
    },
  },
  mounted() {
    const { user } = store.state.AppActiveUser;
    axios.get(`/api/rendez_vouses?statut=Annulé&negociateur=${user.id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((response) => {
        this.annule = String(response.data.length);
      })
      .catch(() => {
        this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page.').goAway(1500);
      });

    axios.get(`/api/rendez_vouses?statut=A venir&negociateur=${user.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((response) => {
        this.aVenir = String(response.data.length);
      });

    axios.get(`/api/rendez_vouses?statut=En attente d'annulation&negociateur=${user.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((response) => {
        this.enAttenteAnnul = String(response.data.length);
      });

    axios.get(`/api/rendez_vouses?statut=Réalisé&negociateur=${user.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((response) => {
        this.termine = String(response.data.length);
      });

    axios.get(`/api/rendez_vouses?statut=A statuer&negociateur=${user.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((response) => {
        this.aStatuer = String(response.data.length);
      });
    // Les commandes
    let statut = '';
    switch (this.$route.query.status) {
      case 'termines':
        statut = 'Réalisé';
        break;
      case 'astatuer':
        statut = 'A statuer';
        break;
      case 'annules':
        statut = 'Annulé';
        break;
      case 'enAttenteAnnul':
        statut = "En attente d'annulation";
        break;
      default:
        statut = 'A venir';
    }

    this.getRendezVous(statut);
    this.gridApi = this.gridOptions.api;
  },
};
</script>
<style>
  .body {
    padding-top: 30px;
  }
  .swiper-slide.historique {
    background: url("../../../assets/images/Slider/historique.png");
    background-size: cover;
  }
  .colors .vx-card__title {
    width: 100%;
    text-align: center;
  }

  .colors .vx-card__title h4 {
    width: 100%;
    padding-bottom: 10px;
    font-size: 38px;
  }

  .container {
    padding: 100px 0;
  }

  .colors .vx-card__title h6 {
    color: #fff !important;
  }

  .colors {
    margin-bottom: 50px
  }
  .ag-cell a {color : #000000}
  a.details  {color : #ffffff !important;}

</style>
